/* forms */

.label-strong {
  font-weight: bold;
}

.label-right {
  text-align: right;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-group-tight {
  margin-bottom: 0.5rem;
}

.form-group-tightest {
  margin-bottom: 0.05rem;
}

.form-control-xs {
  height: calc(1.2em + 0.5rem + 2px);
  padding: 0.15rem 0.15rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.form-control-inline {
  display: inline;
  width: auto;
}

input.ng-touched.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

input.ng-dirty.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

select.ng-touched.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

select.ng-dirty.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

textarea.ng-touched.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

textarea.ng-dirty.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

.input-invalid {
  color: #a94442; /* red */
}

ul.input-invalid {
  margin-bottom: 0;
  -webkit-padding-start: 20px;
  line-height: 1;
}

.ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #a6a6a6;
}

.ui-multiselect-normal .ui-multiselect {
  width: 100%;
}

.ui-multiselect-normal .ui-multiselect-panel {
  max-height: 300px;
}

.ui-multiselect-normal .ui-multiselect-items-wrapper {
  max-height: 250px;
}

.form-design-mode-control {
  border: 1px dotted blue;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
}
