/*
  table theming
  default = as-is
  plain = ui-table-component-plain and ui-table-plain
  striped = ui-table-component-plain and ui-table-striped
*/
/* tone down the column sorting indicators */
body .p-table .p-sortable-column.p-state-highlight {
  background-color: unset;
  color: unset;

  .p-sortable-column-icon {
    color: #848484;
  }
}

body .p-table .p-sortable-column.p-state-highlight a,
body .p-table .p-sortable-column.p-state-highlight a:hover {
  color: unset;
}

body .p-table .p-sortable-column a,
body .p-table .p-sortable-column a:hover {
  color: unset;
}

body .p-table .p-sortable-column:not(.p-state-highlight):hover {
  background-color: unset;
  color: unset;
}

/* footer when used should just have top border */
body .p-table .p-table-tfoot > tr > td {
  border: none;
  border-top:1px solid #c8c8c8;
}



/* tone down the row padding and header borders*/
.p-datatable .p-datatable-header {
    background: initial;
    border-width: 0;
    padding: 0.5rem 0.5rem;
}
.p-datatable .p-datatable-thead > tr > th {
    border-width: 0;
    padding: 0.6rem 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.6rem 0.5rem;
}
.p-treetable .p-treetable-thead > tr > th {
  border-width: 0;
  padding: 0.6rem 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td {
  padding: 0.6rem 0.5rem;
}


/* introduce plain and striped themes */
.p-table-component-plain {
}

body .p-table-component-plain .p-paginator {
  background-color: white;
  border: none;
  border-top: 1px solid gray;
}

body .p-table-component-plain .p-table-caption,
body .p-table-component-plain .p-table-summary {
  background-color: white;
  border: none;
}

.p-table-plain {
}

body .p-table-plain .p-table-thead > tr > th,
body .p-table-striped .p-table-thead > tr > th {
  border: none;
  border-bottom: 1px solid gray;
  background-color: white;
}

body .p-table-plain .p-table-tbody > tr > td,
body .p-table-striped .p-table-tbody > tr > td {
  border: none;
}

body .p-table-plain .p-table-tbody > tr:nth-child(even) {
  background-color: white;
}

/* don't block wordwrap */
.p-datatable-resizeable .p-datatable-thead > tr > th,
.p-datatable-resizeable .p-datatable-tfoot > tr > td,
.p-datatable-resizeable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tbody > tr > td {
  white-space: initial !important;
}

/*
  body .p-table .p-table-thead > tr > th background-color #f4f4f4

  We need this background color for the whole div so the area above the scroll bar has the same background color so
  we don't have an ugly gap when it comes to scenarios where we have a header bar for search, features, add button, etc.
*/
.p-table-scrollable-header {
  background-color: #f4f4f4 !important;
}

/* table column filter overlay does not want any padding for the overlay container just straight to the contents and !important needed for that to happen */
.p-table-column-filter-overlay {
  padding: 0 0 0 0;
}

.p-table-column-filter-overlay .p-overlaypanel .p-overlaypanel-content {
  padding: 0 0 0 0 !important;
}

.p-table-column-filter-overlay .p-overlaypanel-content {
  padding: 0 0 0 0 !important;
}

.p-table-rows-message {
  margin-top: 5px;
  padding-left: 10px;
  margin-right: -100px; /* we don't want a long rows message to push the page number selector too far right */
}

/* In table row drop down menu truncated if we don't make overflow-x visible ...
  bootstrap button dropdown menu appearing behind other controls ... z-index doesn't cut it.*/
.table-responsive {
  overflow-x: visible;
}


/* option for stripped div based row/col instead of table */
.row-striped:nth-of-type(odd) div {
  background-color: #f9f9f9;
}
.row-striped:nth-of-type(even) div {
  background: #FFFFFF;
}

.row-border-top-bottom {
  border-top: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  margin-bottom: -1px;
  /*outline: 1px solid black;*/
}






/* flex table */
@import "breakpoint-sass";
.flex-table {
  $light-color: #ffffff;
  $dark-color: #f2f2f2;
  $md: 500px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border: 1px solid $dark-color;
  font-size: 1rem;
  margin: 0.5rem;
  line-height: 1.5;

  // .flex-table-header
  &-header {
    display: none;
    @include breakpoint($md) {
      font-weight: 700;
      background-color: $dark-color;
    }
  }
  // .flex-table-row
  &-row {
    width: 100%;
    &:nth-of-type(even) { background-color: $dark-color; }
     &:nth-of-type(odd) { background-color: $light-color; }
    @include breakpoint($md) {
      display: flex;
      flex-flow: row nowrap;
      &:nth-of-type(even) { background-color: $light-color; }
      &:nth-of-type(odd) { background-color: $dark-color; }
    }
    // .flex-table-row-item
    &-item {
      display: flex;
      flex-flow: row nowrap;
      flex-grow: 1;
      flex-basis: 0;
      padding: 0.5em;
      word-break: break-word;
      &:before {
        content: attr(data-header);
        width: 30%;
        font-weight: 700;
      }
      @include breakpoint($md) {
        border: 1px solid $light-color;
        padding: 0.5em;
        &:before { content: none; }
      }
    }
  }
}

// generate Flexbox grow-rate utility classes
@for $i from 1 through 10 {
  .u-flex-grow#{$i} {
    flex-grow: $i;
  }
}

